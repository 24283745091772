@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700&family=Montserrat:wght@400;500;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Baloo Da 2', cursive;
        scroll-behavior: smooth;
    }
}

.container-m-auto-px-28 {
    @apply container mx-auto px-28;
}

.btn-theme {
    @apply bg-gradient-to-b from-btn-bg-top to-btn-bg-bottom;
    
}
.btn-theme:hover {
    @apply bg-gradient-to-b from-btn-bg-bottom to-btn-bg-top;
}


.timer_design {
    @apply px-4 py-6 max-md:px-3 max-md:py-2 border bg-color-one text-white rounded-md
}

@screen lg {
    .btn-view-all {
        @apply w-[70px] h-[70px] absolute right-0 transform -translate-y-1/2 translate-x-1/2 top-1/2 text-white text-center rounded-full flex flex-col justify-center items-center;
    }
}

@screen max-md {
    .btn-view-all {
        @apply w-[70px] h-[70px] absolute -bottom-[35px] left-1/2 transform -translate-x-1/2 text-white text-center rounded-full flex flex-col justify-center items-center;
    }
}

.headerShadow {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
}

.min-h-without-footer {
    min-height: calc(100vh - 300px);
}

.min-h-body {
    min-height: calc(100vh - 400px);
}

.login_arrow {
    left: 55%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login_ellipse {
    top: 50%;
    transform: translateY(-50%);
}

/* react modal */
.ReactModal__Content--after-open {
    @apply w-1/2 md:w-11/12;
}

.my-tabs{
    color: #224156 !important;
}
.my-tabs.tab-active{
    background-color: #224156 !important;
    color: #fff !important;
    border: 0;
}